import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { isEmptyObj } from 'src/app/helper/class/utilityHelper';

@Injectable({
  providedIn: 'root'
})
export class CustomerApiService {

  constructor(private http: HttpClient) { }

  saveCustomer(data) {
    return lastValueFrom(this.http.post(`customer/save`, data))
  }
  getListCustomer(ev: any) {
    const lasyP = isEmptyObj(ev) ? '' : '/' + JSON.stringify(ev);
    return lastValueFrom(this.http.get(`customer/getList/${lasyP}`));
  }
  getCustomerById(id: any) {
    return lastValueFrom(this.http.get(`customer/getById/${id}`));
  }

  searchCustomer(terms: string | null, w: any = []) {
    const lasyP = '/' + JSON.stringify(w);
    return lastValueFrom(this.http.get(`customer/search/${terms}${lasyP}`))
  }
}
