import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { isEmptyObj } from 'src/app/helper/class/utilityHelper';
import { ResponseData } from 'src/app/helper/interface/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterApiService {
  constructor(public http: HttpClient) { }

  getById(tbl: string, id: string | number): Promise<ResponseData> {
    return lastValueFrom(this.http.get(`/getById/${tbl}/${id}`))
  }
  searchAgent(terms: any) {
    return lastValueFrom(this.http.get(`/search/staff/${terms}`))
  }
  getFullData(tbl: string, cond: any = '', isFull = false, lazy = {}) {
    //let query: string = '';
    //Array.isArray(cond) ? cond.map(a => query = query + '/' + a) : '';
    const tbl_ext = `/` + JSON.stringify(cond);
    const lasyP = isEmptyObj(lazy) ? '' : '/' + JSON.stringify(lazy);
    if (isFull) {
      return lastValueFrom(this.http.get(`/getfull/${tbl}${tbl_ext}${lasyP}`,))
    } else {
      return lastValueFrom(this.http.get(`/get/${tbl}${tbl_ext}${lasyP}`));
    }
  }

  saveData(tbl: string, data: any) {
    return lastValueFrom(this.http.post(`/saveData/${tbl}`, data))
  }
  saveField(data: any) {
    return lastValueFrom(this.http.post(`/field/save`, data))
  }
  getField(lazy: any = {}) {
    const lasyP = isEmptyObj(lazy) ? '' : '/' + JSON.stringify(lazy);
    return lastValueFrom(this.http.get(`/field/getList${lasyP}`,))
  }

  getMobileApp(lazy: any = {}) {
    const lasyP = isEmptyObj(lazy) ? '' : '/' + JSON.stringify(lazy);
    return lastValueFrom(this.http.get(`/app/getList${lasyP}`,))
  }

  getFieldByid(id: string | number, isFull = true) {
    const d = isFull ? 'true' : 'false';
    return lastValueFrom(this.http.get(`/field/get/${id}`,))
  }

  getChurchByField(id: any) {
    return lastValueFrom(this.http.get(`/church/getByField/${id}`))
  }

  generateEmail(id: string | number, data: any) {
    return lastValueFrom(this.http.post(`/genEmail/${id}`, data))
  }

  getFieldByZone(id: any) {
    let data: any;
    if (Array.isArray(id)) {
      data = JSON.stringify(id);
    }
    return lastValueFrom(this.http.get(`/field/getByZone/${data}`))
  }

  exportData(tName = '', data: any = []) {
    data = JSON.stringify(data);
    return lastValueFrom(this.http.post(`/exportData/${tName}`, data, { responseType: 'blob' }))
  }
  sendEmail(data: Array<any>) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return lastValueFrom(this.http.post(`${environment.emailApiUrl}/sendEmail`, data))
  }
  search(tbl: string, terms: any) {
    return lastValueFrom(this.http.get(`/search/${tbl}/${terms}`))
  }

  searchAlliance(terms) {
    return lastValueFrom(this.http.get(`/alliance/search/${terms}`))
  }
  globalSearch(terms: any) {
    return lastValueFrom(this.http.get(`order/search/${terms}`));
  }
}
