<div class="search_div open">
  <div id="navbar-search" class="navbar-form search-form">
    <span class="p-input-icon-right" style="display: block !important;">
      @if(searchControl?.value){
      <i (click)="searchControl.reset()" class="pi pi-times cursor-pointer"></i>
      }
      <input pInputText type="text" [formControl]="searchControl" class="w-100  "
        placeholder="{{'Search...' | titlecase}}" />
    </span>
  </div>
  <div class="form-group" [formGroup]="filterForm">
    <div class="row clearfix">
      @for (a of filterCol; track a) {
      <div class="col-md-3 col-lg-3">
        <select (change)="searchChange(searchControl)" class="custom-select" [formControlName]="a.tblName">
          <option value="">{{a.tblName | titlecase}}</option>
          @for (b of FORM_SUPPORT_DATA[a.tblName]; track b) {
          <option [value]="b.id">{{b[a.colName || a.tblName+'Name']}}</option>
          }
        </select>
      </div>
      }
    </div>
  </div>
  <span class="mb-1 text-muted ">Search Result <small class="float-end text-muted"> @if (loading) {
      <i class="pi pi-spin pi-spinner"></i>
      }
    </small></span>
  <div class="search_container">
    @if (!resultData?.length) {
    <div class="text-center" style="margin-top: 50%">
      No Result Found
    </div>
    }
    <ul class="right_chat list-unstyled mb-0">
      @for (t of resultData; track t; let i = $index) {
      <li [ngClass]="{'offline': t?.active , 'online' : !t?.active}" class="mb-1" (click)="onConfirm(t)">
        <a href="javascript:void(0);">
          <div class="d-flex">
            <div class="flex-shrink-0">
              <img [src]="t?.avatarPic" imageLoad [alt]="t?.detail" class="w40 h35 rounded">
            </div>
            <div class="flex-grow-1 ms-3">
              <span class="name" [innerHTML]="t?.detail"></span>
              <span class="message" [innerHTML]="t?.mutedText"></span>
              <span class="badge badge-outline status"></span>
            </div>
            <div style="flex: 0;text-align: right;" class="text-muted">
              @if (t?.topEndText) {
              <span style="font-size: small;">
                {{t?.topEndText}}
              </span>
              }
              @if (t?.bottomEndText) {
              <span class="float-right">
                {{t?.bottomEndText}}
              </span>
              }
            </div>
          </div>
        </a>
      </li>
      }
    </ul>
  </div>
</div>