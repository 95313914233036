<!-- <div class="pt-2">
<img imageLoad *ngIf="progress" [src]="filePath" class="img-thumbnail w100 h100">
</div> -->
<!-- <div class="col-xs-12 col-sm-6 col-md-4" *ngFor="let a of file_data  [class.float-left]="""> -->
@if (doc_path) {
<div class="modification-requst-view">
  <div class="thumbnail">
    <img imageLoad class="rounded img-fluid" [src]="doc_path" alt="">
    <div class="btn-group me-2 thumb-btn">
      <a [href]="doc_path" class="btn btn-primary btn-sm" target="_blank"><i class="pi pi-download"></i></a>
      @if (showDelete) {
      <button type="button" class="btn btn-danger btn-sm" (click)="delete()"><i class="icon-trash"></i></button>
      }
    </div>
    @if (remarks) {
    <div class="text-muted img-title">{{remarks}}</div>
    }
  </div>
  <!-- <div class="container_progress">
      <div #progressDiv class="progress mx-auto" data-value='25'>
        <span class="progress-left">
          <span class="progress-bar border-danger"></span>
        </span>
        <span class="progress-right">
          <span class="progress-bar border-danger"></span>
        </span>
      </div>
    </div> -->
</div>
}
<!-- </div> -->