<div class="row clearfix" [formGroup]="dataForm">
  @if (data.length) {
  @for (f of data; track f; let od = $odd) {
  @if (f?.groupTitle || f?.groupStart) {
  <div class="col-md-12 col-lg-12 mt-1 mb-1">
    <h6 class="text-muted">{{f?.groupTitle}}</h6>
  </div>
  }
  @if (!f.hidden) {
  <div [class.hide_me]="f?.invisible" class="form-group col-md-{{f?.col_size? f.col_size : '6'}}">
    <label>{{f?.title_case == 'NONE' ? f.title : f?.title |titlecase}}</label>
    @switch (f.type) {
    @case ('editor') {
    <!-- <app-editor [content]="EDITOR_DATA[f?.colName]"
              (onChangeData)="onDescriptionChange($event,f.colName)"></app-editor> -->
    }
    @case ('chips') {
    <p-chips separator="," (onAdd)="onAddChips($event, f.colName)" [allowDuplicate]="false"
      styleClass="full_width form-control" [formControl]="dataForm.controls[f.colName]"></p-chips>
    }
    @case ('select') {
    @if (f.type == 'select') {
    <p-dropdown [options]="FORM_SUPPORT_DATA[f?.colName]" [optionValue]="f?.selectPrimaryKey"
      [disabled]="f?.readonly? true : false" [readonly]="f?.readonly? true : false"
      [styleClass]="(submitted && dataForm.get(f.colName)?.errors) ? 'error':''"
      [filter]="(FORM_SUPPORT_DATA[f?.colName]?.length || 0) > 10 ? true :false "
      [style]="{'width':'100%' ,'padding' : '0'}" [optionLabel]="f?.selectKeyName"
      (onChange)="f.event?.name == 'change' && f.event?.isCallback ? onChange(f.colName,$event) : f.event?.name == 'change' ? getFullData(f.event.apiTblName,[],f.event.valueAssign,true,f.colName,f.event?.apiFunName) : ''; "
      [placeholder]="DATA_LOADING[f?.colName] ? 'loading...' : (f?.placeholder || 'Select '+f?.title)"
      [formControl]="dataForm.controls[f.colName]"
      [showClear]="f?.readonly? false :  dataForm.controls[f.colName].value ? true :false">
    </p-dropdown>
    }
    }
    @case ('MULTISELECT') {
    <p-multiSelect styleClass="w-100"
      (onChange)="f.event?.name == 'change' && f.event?.isCallback ? onChange(f.colName,$event) : f.event?.name == 'change' ? getFullData(f.event.apiTblName,[],f.event.valueAssign,true,f.colName,f.event?.apiFunName) : ''; "
      [formControl]="dataForm.controls[f.colName]" [options]="FORM_SUPPORT_DATA[f?.colName]"
      [optionLabel]="f?.selectKeyName" [optionValue]="f?.selectPrimaryKey"
      [class.error]="submitted && dataForm.get(f.colName)?.errors"
      [placeholder]="f?.placeholder ? f.placeholder: f.title"></p-multiSelect>
    }
    @case ('checkbox') {
    @for (m of FORM_SUPPORT_DATA[f?.colName]; track m) {
    <label class="ms-3">
      <input [value]="m[f?.selectPrimaryKey]"
        (change)="f.event?.name == 'change' && f.event?.isCallback ? onChange(f.colName,$event) : ''"
        [formControl]="dataForm.controls[f.colName]" type="checkbox" class="form-check-input">
      {{m[f?.selectKeyName]}}
    </label>
    <!-- <p-checkbox class="ms-3" [label]="m[f?.selectKeyName]"
      (change)="f.event?.name == 'change' && f.event?.isCallback ? onChange(f.colName,$event) : ''"
      [formControl]="dataForm.controls[f.colName]" [name]="f?.colName" [value]="m[f?.selectPrimaryKey]"></p-checkbox> -->
    }
    }
    @case ('radio') {
    @for (m of FORM_SUPPORT_DATA[f?.colName]; track m) {
    <label class="ms-3">
      <input [value]="m[f?.selectPrimaryKey]"
        (change)="f.event?.name == 'change' && f.event?.isCallback ? onChange(f.colName,$event) : ''"
        [formControl]="dataForm.controls[f.colName]" type="radio" class="form-check-input">
      {{m[f?.selectKeyName]}}
    </label>
    }
    <!-- </div> -->
    }
    @case ('TEXTAREA') {
    <textarea pInputTextarea class="w-100" (paste)="toremoveWhiteSpace()"
      [class.error]="submitted && dataForm.get(f.colName)?.errors"
      [formControl]="dataForm.controls[f.colName]"></textarea>
    }
    @case ('DATE') {
    <p-calendar #calender [disabled]="f?.readonly" [dateFormat]="f?.dateFormat || dateFormat"
      [view]="f?.dateViewMode || 'date'" [formControl]="dataForm.controls[f.colName]"
      (onSelect)="onChange(f.colName,$event)" [showTime]="f?.showTime" [showSeconds]="f?.showSeconds"
      [timeOnly]="f?.timeOnly" [class.error]="submitted && dataForm.get(f.colName)?.errors"
      [minDate]="f?.minDate || undefined" [maxDate]="f?.maxDate || undefined" class="w-100"
      [monthNavigator]="f?.monthNavigator ?true : false" [yearRange]="f?.dateRange"
      [yearNavigator]="f?.yearNavigator ? true : false" [placeholder]="f?.placeholder ? f.placeholder: f.title"
      [selectionMode]="f?.selectionMode || 'single'">
    </p-calendar>
    }
    @case ('DATETIME') {
    <p-calendar [disabled]="f?.readonly" [dateFormat]="f?.dateFormat || dateFormat" [view]="f?.dateViewMode || 'date'"
      [formControl]="dataForm.controls[f.colName]" (onSelect)="onChange(f.colName,$event)" [showTime]="f?.showTime"
      [showSeconds]="f?.showSeconds" [timeOnly]="f?.timeOnly"
      [class.error]="submitted && dataForm.get(f.colName)?.errors" [monthNavigator]="f?.monthNavigator ?true : false"
      [yearRange]="f?.dateRange" [yearNavigator]="f?.yearNavigator ? true : false" inputStyleClass="form-control"
      [placeholder]="f?.placeholder ? f.placeholder: f.title">
    </p-calendar>
    }
    @case ('FILE') {
    @if (f?.type=='FILE') {
    <app-image-upload #imageUploader (uploadedFileInfo)="didUpload($event,f?.colName)"
      [inputFile]="fileConfig[f?.colName]">
    </app-image-upload>
    }
    }
    @case ('INFO') {
    @if (f?.controlAction?.operation=='MULTIPLE') {
    <span>
      {{dataForm?.value[f?.controlAction.controls[0]]
      * dataForm?.value[f?.controlAction.controls[1]]}} {{f?.controlAction.info_text ||
      ''}}</span>
    }
    @if (f?.controlAction?.operation=='ADD') {
    <span>{{f?.controlAction.controls[0] +
      f?.controlAction.controls[1]}} {{f?.controlAction.info_text || ''}}</span>
    }
    }
    @case ('AUTOCOMPLETE') {
    <p-autoComplete [formControl]="dataForm.controls[f.colName]"
      [class.error]="submitted && dataForm.get(f.colName)?.errors" [style]="{'width':'100%' ,'padding' : '0'}"
      inputStyleClass="{{submitted && dataForm.get(f.colName)?.errors ? 'error':''}}" [inputStyle]="{'width':'100%'}"
      [suggestions]="FORM_SUPPORT_DATA[f?.colName]" [forceSelection]="true" [itemSize]="10"
      (onSelect)="onSelectAutocomplete($event,f)" (completeMethod)="filterItems($event,f)" [field]="f?.selectKeyName">
      <ng-template let-item pTemplate="item">
        <!-- <span class="p-fluid">
                          <p-autoComplete [(ngModel)]="selectedItems" [suggestions]="items"
                            (completeMethod)="filterCountry($event)" [multiple]="true"></p-autoComplete>
                          </span> -->
        <span class="ui-autocomplete-token-label">
          @if (f.autoSearch?.selectFieldKeys) {
          @for (a of f.autoSearch?.selectFieldKeys; track a; let i = $index) {
          {{i !=0 ? ' | ' : '' }} {{item[a]}}
          }
          } @else {
          {{item[f?.selectKeyName]}}
          }
        </span>
      </ng-template>
    </p-autoComplete>
    }
    @case ('COLORPICKER') {
    <p-colorPicker [formControl]="dataForm.controls[f.colName]"
      [class.error]="submitted && dataForm.get(f.colName)?.errors" [style]="{'width':'100%' ,'padding' : '0'}"
      (change)="f.event?.name == 'change' ? onChange(f.colName,$event) : ''; "
      (click)="f.event.name =='click' ? onChange(f.colName,$event) : ''" [disabled]="f?.readonly? true : false"
      [inline]="f?.inline? true:false"></p-colorPicker>
    <strong [style.color]="dataForm.controls[f.colName]?.value">{{dataForm.controls[f.colName]?.value}}</strong>
    }
    @case ('PASSWORD') {
    <p-password (paste)="toremoveWhiteSpace()" [pKeyFilter]="f?.inputKeyFilter || defaultKeyFilter "
      (input)="f?.event?.name == 'input' ? onInput(f.colName,f.event):'';" [disabled]="f?.readonly? true : false"
      (change)="f?.event?.name == 'change' ? onChange(f.colName,$event) : ''; "
      (click)="f?.event?.name =='click' ? onChange(f.colName,$event) : ''" [feedback]="false" inputStyleClass="w-100"
      placeholder="{{f?.placeholder ? f.placeholder: f?.title}}" [style]="{'width':'100%'}"
      [class.error]=" submitted && dataForm.get(f.colName)?.errors" [formControl]="dataForm.controls[f.colName]"
      [toggleMask]="true"></p-password>
    }
    @default {
    <!-- <span
                          class="{{f?.icon_append ? (f?.icon_append?.category=='PREPAND'  ? 'p-input-icon-left' :'p-input-icon-right') : ''}}">
                          <input [style]="{'width':'100%' }"
                            inputStyleClass="form-control {'error' :submitted && dataForm.get(f.colName)?.errors}"
                            pInputText (input)="f?.event?.name == 'input' ? onInput(f.colName,f.event):'';"
                            [readonly]="f?.readonly? true : false"
                            (change)="f?.event?.name == 'change' ? onChange(f.colName,$event) : ''; "
                            (click)="f?.event?.name =='click' ? onChange(f.colName,$event) : ''"
                            placeholder="{{f?.placeholder ? f.placeholder: f?.title}}"
                            [class.error]="submitted && dataForm.get(f.colName)?.errors" class="form-control"
                            [formControl]="dataForm.controls[f.colName]" type="text">
                            <i *ngIf=" f?.icon_append?.icon"
                            class="{{f?.icon_append?.icon}}"></i>
                            <ng-container *ngIf="f?.icon_append ">
                              <div
                                class="input-group-{{f?.icon_append?.category=='APPEND' ? 'append' : 'prepend'}}">
                                <span class="input-group-text">{{f?.icon_append?.text}}</span>
                              </div>
                            </ng-container>
                          </span> -->
    <div class="{{f?.icon_append ? 'input-group input-group-sm' : ''}}">
      <input pInputText (paste)="toremoveWhiteSpace()" [pKeyFilter]="f?.inputKeyFilter || defaultKeyFilter "
        (input)="f?.event?.name == 'input' ? onInput(f.colName,f.event):'';" [readonly]="f?.readonly? true : false"
        (change)="f?.event?.name == 'change' ? onChange(f.colName,$event) : ''; "
        (click)="f?.event?.name =='click' ? onChange(f.colName,$event) : ''"
        placeholder="{{f?.placeholder ? f.placeholder: f?.title}}" class="w-100"
        [class.error]="submitted && dataForm.get(f.colName)?.errors" [formControl]="dataForm.controls[f.colName]"
        type="text">
      @if (f?.icon_append ) {
      <div class="input-group-{{f?.icon_append?.category=='APPEND' ? 'append' : 'prepend'}}">
        <span class="input-group-text">{{f?.icon_append?.text}}</span>
      </div>
      }
    </div>
    }
    }
    @if (f?.info) {
    <span class="helper p-2"> {{f.info}}</span>
    }
    @for (val of f.validator; track val) {
    @if (dataForm?.get(f.colName)?.errors) {
    @if (submitted && dataForm?.get(f.colName)?.errors[val?.name]) {
    <span class="error">
      {{val?.error}}
    </span>
    }
    }
    }
  </div>
  }
  }
  }
</div>