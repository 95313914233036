<div class="input-group" [formGroup]="imageForm" enctype="multipart/form-data" contentType="false">
  <div class="custom-file">
    <input [attr.disabled]="disabled ? '' : null" (change)="handleFileInput($event)"
      [accept]="fileAccessType[data.fileType].accessType" type="file" formControlName="pimage"
      class="custom-file-input" />
    <label for="redirect_link" class="custom-file-label">{{data?.label | titlecase}}</label>
  </div>
</div>
@if (!fileInfos.progress && fileStatusMessage) {
<span class="error">{{fileStatusMessage}}</span>
}
<div class="pt-2">
  @if (!fileInfos.progress) {
  <img imageLoad [src]="fileInfos.filePath" class="img-thumbnail w100 h100">
  }
</div>
@if (fileInfos.progress) {
<div class="progress">
  <div class="progress-bar" [style.width.%]="fileInfos.progress">
    {{fileInfos.progress}}%</div>
</div>
}