<div class="row clearfix">
  <div class="col-md-10 col-sm-10">
    <h1 style="font-size: 18px;" class="text-muted">{{config?.title}}</h1>
  </div>
</div>
@if (basicFormData && mode=='EDIT') {
  <app-form-generator #basicForm (onChangeEvent)="onChange($event)"
    [formData]="basicFormData">
  </app-form-generator>
}
<div class="card">
  <div class="body border-0">
    <div class="row clearfix">
      @for (img of imageData; track img; let i = $index) {
        <div class="col-md-3 col-lg-3">
          @if (img?.action !='3') {
            <image-doc-grid [src]="img.document_path" [showDelete]="mode=='EDIT' ? true : false"
              [primaryKey]="i" (onDelete)="deleteImage($event,img?.action? 'NEW' : 'OLD')"
              [remarks]="img?.remarks">
            </image-doc-grid>
          } @else {
            <span class="text-muted">File Removed</span>
          }
        </div>
      }
    </div>
  </div>
</div>