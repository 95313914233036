import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { isEmptyObj } from 'src/app/helper/class/utilityHelper';

@Injectable({
  providedIn: 'root'
})
export class EventApiService {

  constructor(private http: HttpClient) { }

  saveEventLocation(data) {
    return lastValueFrom(this.http.post('event/saveEventLocation', data))
  }
  getListLocation(ev: any) {
    const lasyP = isEmptyObj(ev) ? '' : '/' + JSON.stringify(ev);
    return lastValueFrom(this.http.get(`event/getLocationList/${lasyP}`));
  }
  getLocationById(id) {
    return lastValueFrom(this.http.get(`event/getLocationDetails/${id}`))
  }
  searchEvent(terms: string | null, w: any = []) {
    const lasyP = '/' + JSON.stringify(w);
    return lastValueFrom(this.http.get(`event/search/${terms}${lasyP}`))
  }
  searchEventLocation(terms: string | null, w: any = []) {
    const lasyP = '/' + JSON.stringify(w);
    return lastValueFrom(this.http.get(`event/locationSearch/${terms}${lasyP}`))
  }
  saveEvent(data) {
    return lastValueFrom(this.http.post('event/saveEvent', data))
  }
  getListEvent(ev: any) {
    const lasyP = isEmptyObj(ev) ? '' : '/' + JSON.stringify(ev);
    return lastValueFrom(this.http.get(`event/getEventList/${lasyP}`));
  }
  getEventLocation() {
    return lastValueFrom(this.http.get(`event/getEvent`));
  }
  geteventById(id) {
    return lastValueFrom(this.http.get(`event/getEventById/${id}`))
  }
  changeStatus(id, status) {
    return lastValueFrom(this.http.get(`event/eventinActive/${id}/${status}`))
  }
  deleteEventLocation(id) {
    return lastValueFrom(this.http.get(`event/locationInActive/${id}`))
  }
}
