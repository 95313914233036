<div class="card">
  <div class="body p-0">
    <div class="row clearfix">
    </div>
    <div class="table-responsive">
      <p-table #d1 [value]="data" [alwaysShowPaginator]="false" [lazy]="tableConfig?.isLazy || false"
        [paginator]="!tableConfig?.noPagination" (onLazyLoad)="loadData($event)" [rows]="defaultRow" [autoLayout]="true"
        [totalRecords]="totalRecords" [globalFilterFields]="cols" [resizableColumns]="true" [first]="first"
        columnResizeMode="expand" [globalFilterFields]="globalFilterField" [responsive]="true"
        tableStyleClass="table  width-full" [virtualScroll]="tableConfig?.virtualScroll || false"
        [scrollable]="tableConfig?.virtualScroll || false" [columns]="cols"
        [scrollHeight]="tableConfig?.scrollHeightPx ? ((data?.length *(tableConfig?.scrollHeightPx || 0)) > 1000 ? '1000px' : ((data?.length || 1) + 1) * (tableConfig?.scrollHeightPx || 100)+'px') :undefined"
        [virtualScrollItemSize]="tableConfig?.virtualScrollItemSize || 0">
        <ng-template pTemplate="caption">
          <div class="d-flex flex-row justify-content-end">
            @if (tableConfig?.globalFilter) {
            <div class="form-group p-2 flex-grow-">
              <input class="w-100" placeholder="Search.." pInputText type="text"
                (input)="d1.filterGlobal($any($event.target).value, 'contains')">
            </div>
            }
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>S/No.</th>
            @for (col of cols; track col; let i = $index) {
            @if (col.sort) {
            <th [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>
            } @else {
            <th> {{col.header}}</th>
            }
            }
            @if (actionBtn?.length) {
            <th>Actions
              <button type="button" (click)="reload()" class="ml-1 btn btn-sm btn-light" title="Reload Table"
                [disabled]="tableLoader">
                <i class="pi pi-refresh" [ngClass]="{'pi-spin pi-spinner': tableLoader}"></i></button>
              @if (tableConfig?.addBtn) {
              <button type="button" (click)="btnAction('','ADD')" class="ml-1 btn btn-sm btn-primary" title="Add New"
                [disabled]="tableLoader">
                <i class="pi pi-plus" [ngClass]="{'pi-spin pi-spinner': tableLoader}"></i></button>
              }
              @if (tableConfig?.exportBtn) {
              <button type="button" (click)="btnAction('','EXPORT')" class="ml-1 btn btn-sm btn-success" title="Export"
                [disabled]="exportLoading || tableLoader">
                <i class="pi pi-file-excel"
                  [ngClass]="{'pi-spin pi-spinner': exportLoading || tableLoader}"></i></button>
              }
            </th>
            }
          </tr>
          @if (tableConfig?.showFilter) {
          <tr>
            <th></th>
            @for (c of cols; track c) {
            @if (c?.filter === true) {
            <th>
              @if (c?.filterCol?.type == 'TEXT' || !c?.filterCol?.type) {
              <p-columnFilter type="text" placeholder="search" matchMode="contains" [showMenu]="false"
                [field]="c?.field"></p-columnFilter>
              }
              @if (c?.filterCol?.type == 'DROPDOWN') {
              <p-columnFilter [field]="c?.colName" matchMode="equals" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown [appendTo]="'body'" [filter]="FORM_SUPPORT_DATA[c?.colName]?.length > 15 ? true : false"
                    (onChange)="filter($event.value)" [options]="FORM_SUPPORT_DATA[c?.colName]"
                    [optionValue]="c?.filterCol?.selectPrimaryKey ||  'key'"
                    [placeholder]="c?.filterCol?.placeholder || 'Any'"
                    [optionLabel]="c?.filterCol?.selectKeyName || 'label'" [showClear]="true">
                    <ng-template let-option pTemplate="item">
                      @if (DATA_LOADING[c?.colName]) {
                      <span class="customer-badge status">{{ 'loading...' }}</span>
                      }
                      <span class="customer-badge status">{{option[c?.filterCol?.selectKeyName
                        || 'label']}}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
              }
              @if (c?.filterCol?.type == 'DATE') {
              <p-columnFilter [field]="c?.field" matchMode="equals" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-calendar [readonlyInput]="true" dateFormat="dd/mm/yy" [showButtonBar]="true" appendTo="body"
                    [view]="c?.filterCol?.dateViewMode || 'date'" (onSelect)="filter($event)"
                    (onClearClick)="filter($event)" [maxDate]="c?.filterCol?.maxDate"
                    [monthNavigator]="c?.filterCol?.monthNavigator ?true : false"
                    [yearNavigator]="c?.filterCol?.yearNavigator ? true : false" [minDate]="c?.filterCol?.minDate"
                    [yearRange]="c?.filterCol?.yearRange || yearRange"
                    [placeholder]="c?.filterCol?.placeholder || 'Any'">
                  </p-calendar>
                </ng-template>
              </p-columnFilter>
              }
            </th>
            } @else {
            <th></th>
            }
            }
            @if (actionBtn?.length) {
            <th></th>
            }
          </tr>
          }
          @if (tableLoader) {
          <tr class="tableloader">
            <td [attr.colspan]="(cols.length+1) + (actionBtn?.length || 0)" class="text-center"><i
                class="pi pi-spin pi-spinner"></i>Please
              wait data fetching...</td>
          </tr>
          }
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr class="tableloader">
            <td [attr.colspan]="(cols.length+1) + (actionBtn?.length || 0)" class="text-center">No data found.</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-request let-rowIndex="rowIndex">
          <tr (click)="onSelectData(request)"
            [ngClass]="{'bg-primary text-light selectedRow' : request.selectable,'cursor-pointer' : request.selectable}"
            class="{{request.selectable ? 'cursor-pointer bg-primary text-light' : request.classList ? request.classList : '' }}">
            <td>{{rowIndex + 1}}</td>
            @for (col of cols; track col; let i = $index) {
            @if (!col?.isEditable) {
            @switch (col?.type) {
            @case ('IMAGE') {
            <td>
              <img imageLoad [src]="request[col.field]" alt="Document" class="w35 h35 rounded">
            </td>
            }
            <!-- <td colspan="" *ngSwitchCase="'NAME_WITH_IMG'">
                                  <span>
                                    <img imageLoad [src]="request[col.field]" alt="Document"
                                      class="w35 h35 rounded">
                                    </span>
                                    <span>
                                      <span *ngIf="col?.icon_append?.text && request[col.field] ; else noAppend"
                                      [innerHTML]="request[col.field] + ' ' +col?.icon_append?.text || '' | safeHtml"></span>
                                      <ng-template #noAppend><span
                                      [innerHTML]="request[col.field] || '' | safeHtml"></span></ng-template>
                                      <ng-container
                                        *ngFor="let f of request[col.field] | transformArray:',':' & '">
                                        <strong><a title="Staff Info" (click)="openStaffInfo(f)"
                                        href="javascript:void(0)">{{f}}</a></strong>
                                      </ng-container>
                                    </span>
                                  </td> -->
            @case ('DATE') {
            <td>
              {{request[col.field] | dateFormat:'dd-MM-yyyy'}}</td>
            }
            @case ('CURRENCY') {
            <td>{{request[col.field] | currencyFormat}}</td>
            }
            @case ('STATUS') {
            <td>
              <span class="badge"
                [ngClass]="{'badge-success' : +request[col.field]==1 , 'badge-danger' : +request[col.field] ==0, 'badge-warning' : +request[col.field] == 2 }">{{statusArray[request[col.field]]}}</span>
            </td>
            }
            @case ('DROPDOWN') {
            <td>
              @for (m of FORM_SUPPORT_DATA[col?.colName]; track m) {
              @if (m[col?.filterCol?.selectPrimaryKey || 'key'] == request[col.colName]) {
              @if (m?.color ) {
              <span class="badge bg-{{m?.color || 'info'}}">{{
                m[col?.filterCol?.selectKeyName || 'label']}}</span>
              }@else{
              {{ m[col?.filterCol?.selectKeyName || 'label']}}
              }
              }
              }
            </td>
            }
            @case ('STAFF_EMP_ID') {
            <td>
              @for (f of request[col.field] | transformArray:',':' & '; track f) {
              <strong><a title="Staff Info" (click)="openStaffInfo(f)" href="javascript:void(0)">{{f}}</a></strong>
              }
            </td>
            }
            @case ('VIEW_INFO') {
            <td>
              @for (f of request[col.field] | transformArray:',':' & '; track f) {
              <strong><a title="View Info" (click)="btnAnchorAction(f,request)" href="javascript:void(0)"
                  [innerHTML]="f |safeHtml"></a></strong>
              }
            </td>
            }
            @case ('VIEW_TAG_INFO') {
            <td style="white-space: normal; min-width: 100px;max-width:30vw;" class="cursor-pointer">
              @for (f of request[col.field]; track f) {
              <span (click)="btnTagInfo(f,request)" [innerHTML]="f?.text |safeHtml"></span>
              }
            </td>
            }
            @default {
            <td>
              @if (col?.icon_append?.text && request[col.field] ) {
              <span [innerHTML]="request[col.field] + ' ' +col?.icon_append?.text || '' | safeHtml"></span>
              } @else {
              <span [innerHTML]="request[col.field] || '' | safeHtml"></span>
              }
              <ng-template #noAppend><span [innerHTML]="request[col.field] || '' | safeHtml"></span></ng-template>
            </td>
            }
            }
            } @else {
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text"
                    (keydown.enter)="updateRow(request,col?.field,request[col.field],rowIndex)"
                    (change)="updateRow(request,col?.field,request[col.field],rowIndex)" class="w-100 "
                    [(ngModel)]="request[col.field]" required>
                </ng-template>
                <ng-template pTemplate="output">
                  <span>{{request[col.field]}}</span>
                </ng-template>
              </p-cellEditor>
            </td>
            }
            <ng-template #isEditable>
              <td pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" class="w-100" (keydown.enter)="
                      updateRow(request,col?.field,request[col.field],rowIndex)"
                      (change)="updateRow(request,col?.field,request[col.field],rowIndex)" class="w-100"
                      [(ngModel)]="request[col.field]" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span>{{request[col.field]}}</span>
                  </ng-template>
                </p-cellEditor>
              </td>
            </ng-template>
            }
            @if (actionBtn?.length && makeChange) {
            <td>
              @for (b of actionBtn; track b) {
              @switch (b?.category) {
              @case ('TEXT') {
              @if (!b.condition || (b.condition && (request | conditionOperator : b.condition))) {
              <span>{{b.name}}</span>
              }
              }
              @default {
              @if (!b.condition || (b.condition && (request | conditionOperator : b.condition))) {
              <button class="btn btn-sm ms-1 {{b.class || ''}}" [class.btn-default]="!b.class"
                [title]="b?.title | titlecase"
                (click)="$event.stopPropagation();btnAction(request[primaryKey] || '', b?.type,request)"><i
                  class="{{b.iconClass || 'text-default'}} {{b.icon}}"></i></button>
              }
              }
              }
              }
            </td>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-state>
          Total Request {{ state.first ? state.first+1 :1}} -
          {{ (state.first+state.rows) > state.totalRecords ?
          state.totalRecords : state.rows+state.first }}
          of {{state.totalRecords}}
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<ng-template #rowTable let request let-col>
  <td>
  </td>
</ng-template>