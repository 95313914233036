@if (!isPage) {
  <div class="modal-header">
    <h6 class="mb-0 modal-title"> <b>{{title | titlecase}}</b></h6>
    <button type="button" class="close" (click)="close()"><span>X</span></button>
  </div>
}
<div class="card" [hidden]="hideMe">
  <div class="body" [formGroup]="addressForm">
    @if (isPage) {
      <div class="row clearfix">
        <div class="col-md-6 col-lg-6">
          <label>
            <b>{{title | titlecase}}</b>
          </label>
        </div>
      </div>
    }
    @if (!data?.hideBasic) {
      <div class="form-group">
        <div class="row clearfix">
          <div class="col-md-6 col-lg-6">
            <label>House No / Plot</label>
            <input formControlName="street" [class.error]="submitted && addressForm.get('street')?.errors"
              class="form-control" type="text">
              @if (addressForm.get('street')?.errors?.required) {
                <span class="error ml-2">
                House / Plot Number Required</span>
              }
            </div>
            <div class="col-md-6 col-lg-6">
              <label>
                Address Line
              </label>
              <input formControlName="address" [class.error]="submitted && addressForm.get('address')?.errors"
                class="form-control" type="text">
              </div>
            </div>
          </div>
        }
        <div class="row clearfix">
          <div class="form-group col-6">
            <label>Country</label>
            <select (change)="getFullData([],'stateData',true)"
              [class.error]="submitted && addressForm.get('country')?.errors" class="custom-select"
              formControlName="country">
              <option value="">{{masterLoading['countryData'] ? 'loading...' : 'Select Country'}}</option>
              @for (m of countryData; track m) {
                <option [value]="m?.id">
                  {{m?.countryName}}
                </option>
              }
            </select>
            @if (addressForm.get('country').dirty && addressForm.get('country')?.errors?.required) {
              <span class="error"
                >
                Select any country.
              </span>
            }
          </div>
          <div class="form-group col-6">
            <label>State</label>
            <select (change)="getFullData([],'districtData',true)"
              [class.error]="submitted && addressForm.get('state')?.errors" class="custom-select"
              formControlName="state">
              <option value="">{{masterLoading['stateData'] ? 'loading...' : 'Select State'}}</option>
              @for (m of stateData; track m) {
                <option [value]="m?.id">
                  {{m?.stateName | titlecase}}
                </option>
              }
            </select>
            @if (addressForm.get('state').dirty && addressForm.get('state')?.errors?.required) {
              <span class="error"
                >
                Select any state.
              </span>
            }
          </div>
          <div class="form-group col-6">
            <label>District</label>
            <select (change)="getFullData([],'subDistrictData',true)"
              [class.error]="submitted && addressForm.get('district')?.errors" class="custom-select"
              formControlName="district">
              <option value="">{{masterLoading['districtData'] ? 'loading...' : 'Select District'}}</option>
              @for (m of districtData; track m) {
                <option [value]="m?.id">
                  {{m?.districtName | titlecase}}
                </option>
              }
            </select>
            @if (addressForm.get('district').dirty && addressForm.get('district')?.errors?.required) {
              <span class="error"
                >
                Select any district.
              </span>
            }
          </div>
          <ng-container>
            <div class="form-group col-6">
              <label>Sub District</label>
              <select (change)="getFullData([],'autocompleteLevelValue',true)"
                [class.error]="submitted && addressForm.get('s_district')?.errors" class="custom-select"
                formControlName="s_district">
                <option value="">{{masterLoading['subDistrictData'] ? 'loading...' : 'Select Sub District'}}
                </option>
                @for (m of subDistrictData; track m) {
                  <option [value]="m?.id">
                    {{m?.subDistrictName | titlecase}}
                  </option>
                }
              </select>
              @if (addressForm.get('s_district').dirty && addressForm.get('s_district')?.errors?.required) {
                <span class="error"
                  >
                  Select any sub district.
                </span>
              }
            </div>
            @if (addressForm.get('level')) {
              <div class="form-group col-6">
                @for (m of LEVEL | keyvalue; track m) {
                  <label class="fancy-radio custom-color-green"><input (change)="onChangeLevel()"
                  formControlName="level" [value]="m?.value" type="radio"><span><i></i> {{m?.key |
                titlecase}}</span></label>
              }
              <p-dropdown [style]="{'width':'100%' ,'padding' : '0'}"
                styleClass="form-control {'error' : addressForm.get('levelSearch')?.errors}" optionValue='id'
                [placeholder]="levelFilterKey ==LEVEL.CITY ? 'Select a City' : 'Select a Panchayat'"
                (onChange)="getFullData([],'autocompleteValue',true)" [options]="autocompleteLevelValue"
              formControlName="levelSearch" [optionLabel]="levelFilterKey"></p-dropdown>
              @if (addressForm.get('levelSearch')?.errors?.required || (addressForm.get('levelSearch')?.value | json)=='{}') {
                <span class="error"
                  >
                  {{levelFilterKey ==LEVEL.CITY ? 'City' : 'Panchayat'}} Name Required
                </span>
              }
            </div>
          }
          @if (addressForm.get('locationSearch')) {
            <div class="form-group col-6">
              <label>{{levelFilterKey ==LEVEL.CITY ? 'Ward Name' : 'Village Name' }}</label>
              <p-dropdown [style]="{'width':'100%' ,'padding' : '0'}"
                styleClass="form-control {'error' : addressForm.get('locationSearch')?.errors}" optionValue='id'
                [placeholder]="levelFilterKey ==LEVEL.CITY ? 'Select a Ward' : 'Select a Village'"
                [options]="autocompleteValue" formControlName="locationSearch" [optionLabel]="filterKey">
              </p-dropdown>
              @if (addressForm.get('locationSearch')?.errors?.required || (addressForm.get('locationSearch')?.value |json )== '{}') {
                <span class="error"
                  >
                  location should'nt be blank
                </span>
              }
            </div>
          }
        </ng-container>
        @if (!data?.hideBasic) {
          <div class="form-group col-6">
            <label>Pin Code</label>
            <input formControlName="pincode" [class.error]="submitted && addressForm.get('pincode')?.errors"
              class="form-control" type="text">
              @if (addressForm.get('pincode')?.errors?.required) {
                <span class="error">
                  Pin code is Required.
                </span>
              }
              @if (addressForm.get('pincode')?.errors?.pattern) {
                <span class="error">
                  Pin code should be valid.
                </span>
              }
            </div>
          }
        </div>
      </div>
    </div>
    @if (!isPage) {
      <div class="modal-footer">
        <button type="button" class="btn btn-round btn-default" (click)="close()">Close</button>
        <button type="button" class="btn btn-round btn-primary" (click)="submit()">Save changes</button>
      </div>
    }