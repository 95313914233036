<div class="alert-box">
  <div class="modal-header">
    <h6 class="modal-title">{{data?.title || 'Confirm'}}</h6>
    @if (!data?.disableClose) {
    <button type="button" class="close btn btn-sm" aria-label="Close" (click)="onCancel()">
      <span><i class="pi pi-times"></i></span>
    </button>
    }
  </div>
  <div class="modal-body">
    @if (type == 'CONFIRM') {
    {{data?.message ||'Are you sure want to delete this?'}}
    }
    @if (data?.isFormField && formField.length) {
    <div class="m-2">
      <app-form-generator #basicForm [dynamicValidator]="dynamicValidator || []" (onChangeEvent)="onChange($event)"
        [formData]="formField">
      </app-form-generator>
    </div>
    }
  </div>
  @if (data?.info) {
  <span class="text-info m-2">{{data?.info}}</span>
  }
  <div class="modal-footer">
    <button type="button" [disabled]="data.isFormField && isValid" class="btn btn-success pull-right"
      (click)="onConfirm()">{{data?.btnOK ||'Yes'}}</button>
    <button type="button" class="btn btn-warning pull-left" (click)="onCancel()">{{data?.btnCancel ||
      'No'}}</button>
  </div>
</div>