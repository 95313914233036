<div class="alert-box">
  <div class="modal-header">
    <h6 class="modal-title">{{data?.title || 'Info'}}</h6>
    <button type="button" class="close btn btn-sm" aria-label="Close" (click)="onCancel()">
      <span><i class="pi pi-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    @if (type != 'FORM') {
    <div [innerHTML]="data?.content"></div>
    }
    @if (type == 'FORM') {
    <info-view [modifyData]="data?.modifyData || {}" [mode]="mode" [sourceData]="data?.sourceData"
      [formData]="data?.formData"></info-view>
    }
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">{{data?.btn||'Ok'}}</button>
  </div>
</div>