import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RESPONSE_CODE } from 'src/app/helper/class/app-constant';
import { cloneData, isArray } from 'src/app/helper/class/utilityHelper';
import { ResponseData, whereField } from 'src/app/helper/interface/response';
import { CustomerApiService } from 'src/app/modules/customer/service/customer-api.service';
import { EventApiService } from 'src/app/modules/events/service/event-api.service';
import { MasterApiService } from 'src/app/modules/master/service/master-api.service';
import { searchModal, searchModalResult, searchType } from 'src/app/shared/interface/modal-interface';


@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit, OnDestroy {
  data: searchModal = {} as searchModal;
  loading: boolean = false;
  searchSub: Subscription = {} as Subscription;
  public onClose: Subject<boolean> | any;
  searchControl: UntypedFormControl = {} as UntypedFormControl;
  resultData: searchModalResult[] = [];
  filterForm: UntypedFormGroup = new UntypedFormGroup({});
  filterCol: any = [];
  DATA_LOADING: any = {};
  FORM_SUPPORT_DATA: any = {};
  constructor(
    private masterApi: MasterApiService,
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig, // get the dynamicdialog data using these config also
    private eventApi: EventApiService,
    private customerApi: CustomerApiService,
  ) { }


  public ngOnInit(): void {
    this.onClose = new Subject();
    this.initForm();
  }


  initForm() {
    const f: any = {};
    this.filterCol.forEach((a: any) => f[a.tblName] = new UntypedFormControl(""));
    this.filterForm = new UntypedFormGroup(f);
    this.searchControl = new UntypedFormControl();
    if (typeof this.searchSub.unsubscribe == "function") {
      this.searchSub?.unsubscribe();
    }
    this.searchSub = this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(v => {
      this.searchData(v || null);
    })
  }


  getFullData(tblName: any, cond = [], colName: any = '', apiFun: string = '') {
    if (tblName || apiFun) {
      this.DATA_LOADING[tblName] = true;
      let api: any;
      if (apiFun) {
        //@ts-ignore
        api = this.masterApi[apiFun](cond);
      } else {
        api = this.masterApi.getFullData(tblName, cond, false);
      }
      api.then((res: ResponseData | any) => {
        if (res.statusCode == RESPONSE_CODE.SUCCESS) {
          let re: any = res.result;
          this.data.whereField?.forEach((a: any) => {
            if (a.colName == tblName) {
              const val = isArray(a.value) ? a.value : [a.value];
              const cond = val.filter((a: any) => +a > 0);
              if (cond.length) {
                re = [];
                res.result.forEach((b: any) => {
                  if (a.value.includes(b.id)) {
                    re.push(b);
                  }
                })
              }
            }
          })
          this.FORM_SUPPORT_DATA[tblName] = re;
        } else {
          this.FORM_SUPPORT_DATA[tblName] = [];
        }
      }).finally(() => this.DATA_LOADING[tblName] = false)
    }
  }

  mapFormValue() {
    if (this.data.filterShow) {
      switch (this.data.type) {
        case 'CUSTOMER':
          this.filterCol = [{ tblName: 'zone' }, { tblName: 'department', colName: 'dName' }];
          break;
        case 'EVENT':
          this.filterCol = [{ tblName: 'trust', colName: 'trustName' }, { tblName: 'payroll_group', colName: 'payroll_groupName' }];
          break;
        case 'LOCATION':
          this.filterCol = [];
          break;
      }
      this.filterCol.forEach((a: any) => this.getFullData(a.tblName, [], a.colName || a + 'Name'));
    }
    this.initForm();
  }

  setInput(data: any) {
    this.data = data;
    this.mapFormValue();
    this.searchData(null);
  }
  removeUnderscore(t: string) {
    return t.replace(/_/g, " ");
  }

  mapToResultData(data: any, type: searchType = 'CUSTOMER') {
    const mapElement: searchModalResult | any = {};
    switch (this.data.type) {
      case 'CUSTOMER':
        mapElement.avatarPic = 'profile_img_path';
        mapElement.avatarTxt = 'customer_name';
        mapElement.mutedText = 'mobile_no';
        mapElement.detail = 'customer_name';
        mapElement.topEndText = 'customer_id';
        mapElement.bottomEndText = 'email_id';
        mapElement.active = 'deleted_at';
        break;
      case 'EVENT':
        mapElement.avatarPic = 'profile_img_path';
        mapElement.avatarTxt = 'name';
        mapElement.mutedText = 'event_location_code';
        mapElement.detail = 'eventName';
        mapElement.topEndText = 'village_name';
        mapElement.bottomEndText = 'event_code';
        mapElement.active = 'deleted_at';
        break;
      case 'LOCATION':
        mapElement.avatarPic = 'profile_img_path';
        mapElement.avatarTxt = 'ap_name';
        mapElement.mutedText = 'event_location_code';
        mapElement.detail = 'locationName';
        mapElement.topEndText = 'ap_name';
        mapElement.bottomEndText = 'user_name';
        mapElement.active = 'deleted_at';
        break;
    }
    const dt = ['avatarPic', 'avatarTxt', 'mutedText', 'topEndText', 'bottomEndText', 'active', 'detail'];
    data.forEach((a: any) => {
      const s: any = {};
      dt.forEach(b => {
        const spiV = mapElement[b].split('+') || [];
        s[b] = (a[spiV[0]] || '') + (spiV[1] ? '   <span class="text-primary">' : '') + (spiV[1] ? a[spiV[1]] || '' + '</span>' : '');
      })
      this.resultData.push({ ...s, ...a });
    });
  }
  searchChange(value: any) {
    if (value.value) {
      this.searchData(value.value);
    } else {
      this.searchData(null)
    }

  }

  searchData(terms: string | null, initial: boolean = false) {
    this.loading = true;
    const where: whereField[] = this.data.whereField ? cloneData(this.data.whereField) : [];
    if (this.data.activeOnly) {
      if (isArray(where)) {
        where?.push({ colName: 'deleted_at', operation: 'AND', value: null })
      }
    }
    if (this.data.skipKey && this.data.skipData) {
      if (isArray(where)) {
        where?.push({ colName: this.data.skipKey, operation: 'AND', value: this.data.skipData })
      }
    }
    if (this.filterCol.length) {
      this.filterCol.forEach((a: any) => {
        const v = this.filterForm.value[a.tblName] || '';
        if (v) {
          const f = where.findIndex((c: any) => c.colName == a.tblName);
          if (f > -1) { where.splice(f, 1); }
          where?.push({ colName: a.tblName, value: v, operation: 'AND' });
        }
      })
    }
    let api: any;
    switch (this.data?.type) {
      case 'EVENT':
        api = this.eventApi.searchEvent(terms, where);
        break;
      case 'CUSTOMER':
        api = this.customerApi.searchCustomer(terms, where);
        break;
      case 'LOCATION':
        api = this.eventApi.searchEventLocation(terms, where);
        break;
    }
    api.then((res: ResponseData | any) => {
      this.resultData = [];
      if (res.statusCode == RESPONSE_CODE.SUCCESS) {
        this.mapToResultData(res.result);
      }
    }).finally(() => {
      this.loading = false;
    })
  }

  public onConfirm(data: any): void {
    // this.onClose.next(data);
    // this._bsModalRef.hide();
    this.closeModal(data)
  }

  public onCancel(): void {
    // this.onClose.next(false);
    // this._bsModalRef.hide();
    this.closeModal(false)
  }

  closeModal(data?) {
    this.dialogRef.close(data)
  }

  ngOnDestroy(): void {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }
}