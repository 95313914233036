@if (isModal) {
<div class="modal-header " [style]="pageInfo.style">
  <h6 class="mb-0 modal-title" >{{pageInfo?.title | titlecase}}</h6>
  <!-- <button type="button" class="close" (click)="close()"><span>x</span></button> -->
  <button type="button" class="close btn btn-sm" (click)="close()">
    <span><i class="pi pi-times"></i></span>
  </button>
</div>
}
@if (!isModal) {
<div class="row clearfix" style="padding: 20px 20px 10px 20px;">
  <div class="col-md-6 col-sm-12">
    <h5 class="page-title">{{pageInfo?.title}}</h5>
  </div>
  <div class="col-md-6 col-sm-12 text-right hidden-xs bd-col">
    <div class="btn-group blocks">
      @if (pageInfo?.buttonShowBtn;) {
      @for (a of (isArray(pageInfo?.button) ?pageInfo?.button || [] : [pageInfo?.button || {} ] ); track a) {
      <button (click)="btnClick(a)" class="btn btn-sm {{a?.class || 'btn-primary'}}" title="{{a?.title}}"><i
          [class.icon-list]="!a?.icon" class="{{a?.icon}}"></i>
        {{a?.title}}</button>
      }
      }
    </div>
    <ng-template #btnBack>
      <a href="javascript:void(0)" (click)="close()" class="btn btn-sm btn-primary" title="Back"><i
          class="pi pi-arrow-left"></i> Back</a>
    </ng-template>
    @if (pageInfo?.info_text) {
    <span class="text-muted text-right lead" style="padding-left: 73vw;">{{pageInfo?.info_text}}</span>
    }
  </div>
</div>
}